import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';
import Button from '../../components/ui/Button';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import playIcon from '../../assets/icons/play.svg';
import pauseIcon from '../../assets/icons/pause.svg';
import { JobStatus } from '../../bdreams/FaceSwap';
import { FaceSwapAPI } from '../../bdreams/FaceSwapAPI';
import maximizeIcon from '../../assets/icons/maximize.svg';
import ResultShare from '../../components/ResultShare';
// @ts-ignore
import _debounce from 'lodash.debounce';
import VideoVolume from '../../components/ui/VideoVolume';
import NotifyModal from '../../components/NotifyModal';
import { Box, LinearProgress } from '@mui/material';
import { Share } from '@capacitor/share';
import { isAndroid, isBrowser, isFirefox } from 'react-device-detect';
import { getYoutubeLink } from '../../helpers/utils';
import triggerDataLayer, {
    DataLayerEventPath,
} from '../../helpers/dataLayerTrigger';
import ErrorModal from '../../components/ErrorModal';
import InAppSpy from 'inapp-spy';
import SaveVideoInAppModal from '../../components/SaveVideoInAppModal';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// @ts-ignore
const { isInApp } = InAppSpy();

const Result = ({ festiveModeOn }: { festiveModeOn: boolean }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const isFrenchLanguage = language === 'fr';
    const isItalianLanguage = language === 'it';
    const isGermanLanguage = language === 'de';
    const isPortugueseLanguage = language === 'pt-BR';
    const isSpanishLanguage = language === 'es';
    const { jobId } = useParams();
    // eslint-disable-next-line
    const [progress, setProgress] = useState<number>(0);
    const [pageState, setPageState] = useState<'initial' | 'sent' | 'isReady'>(
        'initial'
    );
    const videoRef = useRef<any>(null);
    const [isVideoPlays, setIsVideoPlays] = useState<boolean>(true);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [posterTimeSecs, setPosterTimeSecs] = useState<number>(0);
    const [videoTitle, setVideoTitle] = useState<string | null>(null);
    const [videoPoster, setVideoPoster] = useState<string | null>(null);
    const [videoShortUrl, setVideoShortUrl] = useState<string | null>(null);
    const lottieAnimationRef = useRef<any>(null);
    const [isMaximized, setIsMaximized] = useState<boolean>(false);
    const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
    const [hideVideoControls, setHideVideoControls] = useState<boolean>(false);
    const [openNotifyModal, setOpenNotifyModal] = useState<boolean>(false);
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
    const [windowWidth, setWindowWidth] = useState<number>(0);
    const [sceneNumber, setSceneNumber] = useState<number>(-1);
    const [playsCount, setPlaysCount] = useState<number>(0);
    const [isPageReady, setIsPageReady] = useState<boolean>(false);
    const [openSaveVideoInAppModal, setOpenSaveVideoInAppModal] =
        useState<boolean>(false);

    useEffect(() => {
        document.title = t('video_page_title');
    }, [t]);

    useEffect(() => {
        handleWindowWidthChange();
        window.addEventListener('resize', handleWindowWidthChange);
        return () => {
            window.removeEventListener('resize', handleWindowWidthChange);
        };
    }, []);

    useEffect(() => {
        if (jobId) {
            pollJobStatus(jobId);
        } else {
            setOpenErrorModal(true);
            console.error('No jobId not provided');
        }
        // eslint-disable-next-line
    }, [jobId]);

    const handleWindowWidthChange = () => {
        setWindowWidth(window.innerWidth);
    };

    const fetchVideoUrl = async () => {
        try {
            const videoResult = await FaceSwapAPI.jobVideo(jobId as string);
            if (videoResult.landingUrl && false) {
            } else {
                setVideoUrl(videoResult.url);
                setVideoTitle(videoResult.title);
                setVideoShortUrl(videoResult.shortUrl);
                setVideoPoster(videoResult.posterUrl);
                setPosterTimeSecs(videoResult.posterTimeSecs);
                const videoSceneNumber = FaceSwapAPI.videos.findIndex(
                    (apiVideo) => apiVideo.id === videoResult.swapVideoId
                );
                setSceneNumber(videoSceneNumber);
                setPageState('isReady');
                triggerDataLayer('video_ready', {
                    scene_number: `scene ${videoSceneNumber + 1}`,
                    scene_description: videoResult.title,
                    path: festiveModeOn
                        ? DataLayerEventPath.FESTIVE
                        : DataLayerEventPath.STANDARD,
                });
            }
        } catch (error) {
            console.error('Failed to fetch video URL:', error);
        }
    };

    const firstCheckStatusOnPageLoad = async (jobId: string) => {
        if (!jobId) {
            setOpenErrorModal(true);
            return;
        }
        try {
            const job = await FaceSwapAPI.jobStatus(jobId);
            const jobStatus = job.status;
            switch (jobStatus) {
                case JobStatus.Success:
                    // navigate('/result', { state: { jobId } });
                    setOpenNotifyModal(false);
                    setProgress(1);
                    fetchVideoUrl()
                        .then(() => {
                            setIsPageReady(true);
                        })
                        .catch(() => {
                            setOpenErrorModal(true);
                        });
                    break;
                case JobStatus.Failed:
                    setProgress(0);
                    setOpenErrorModal(true);
                    break;
                default:
                    setProgress(job.progress);
                    setIsPageReady(true);
                    return true;
            }
        } catch {
            setOpenErrorModal(true);
        }
    };

    const pollJobStatus = (jobId: any) => {
        const checkStatus = async (count?: number) => {
            try {
                if (window.location.pathname.split('/')[2] !== `result`) {
                    return;
                }
                const job = await FaceSwapAPI.jobStatus(jobId);
                const jobStatus = job.status;
                switch (jobStatus) {
                    case JobStatus.Success:
                        // navigate('/result', { state: { jobId } });
                        setOpenNotifyModal(false);
                        setProgress(1);
                        fetchVideoUrl();
                        break;
                    case JobStatus.Failed:
                        setProgress(0);
                        setOpenErrorModal(true);
                        break;
                    default:
                        // if there has been more than 3 minutes of inactivity, notify the user by email
                        if (job.progress < 0.9 && count === 90) {
                            setOpenNotifyModal(true);
                        }
                        setProgress(job.progress);
                        const timeoutId = setTimeout(
                            () => checkStatus((count || 0) + 1),
                            FaceSwapAPI.jobPollInterval * 1000
                        );
                        localStorage.setItem('jobRunId', timeoutId.toString());
                        break;
                }
            } catch (error: Error | any) {
                console.log('Error fetching job status');
                console.log(error);

                // Schedule the next call for status as usual
                const timeoutId = setTimeout(
                    () => checkStatus((count || 0) + 1),
                    FaceSwapAPI.jobPollInterval * 1000
                );
                localStorage.setItem('jobRunId', timeoutId.toString());
                // setOpenErrorModal(true)
            }
        };
        firstCheckStatusOnPageLoad(jobId)
            .then((value) => {
                if (value) {
                    const timeoutId = setTimeout(
                        checkStatus,
                        FaceSwapAPI.jobPollInterval * 1000
                    );
                    localStorage.setItem('jobRunId', timeoutId.toString());
                }
            })
            .catch(() => {
                setOpenErrorModal(true);
            });
    };

    useEffect(() => {
        if (isVideoPlays) {
            videoRef.current?.play();
        } else {
            videoRef.current?.pause();
        }
    }, [videoUrl, isVideoPlays]);

    useEffect(() => {
        if (lottieAnimationRef?.current?.el) {
            lottieAnimationRef.current.el.tabIndex = -1;
        }
        //eslint-disable-next-line
    }, [lottieAnimationRef.current]);

    const handleSharingDesktop = async () => {
        setShareDialogOpen(true);
    };

    const handleSharing = async () => {
        const { value } = await Share.canShare();
        if (value) {
            await Share.share({
                title: videoTitle as string,
                url: videoShortUrl as string,
                text: t('text_to_share'),
            });
        } else {
            setShareDialogOpen(true);
        }
    };

    useEffect(() => {
        if (pageState === 'isReady') {
            try {
                const canAutoPlay = videoRef.current?.play();
                canAutoPlay
                    .then(() => {
                        setIsVideoPlays(true);
                        setPlaysCount(1);
                    })
                    .catch(() => {
                        setIsVideoPlays(false);
                    });
            } catch {
                //pass
                setIsVideoPlays(false);
            }
        }
    }, [pageState]);

    useEffect(() => {
        const handleKeyUp = (e: any) => {
            if (pageState === 'isReady') {
                if (e.key === 'f') {
                    setIsMaximized(true);
                } else if (e.key === 'Escape') {
                    setIsMaximized(false);
                }
            }
        };
        window.document.addEventListener('keyup', handleKeyUp);
        return () => {
            window.document.removeEventListener('keyup', handleKeyUp);
            if (localStorage.getItem('jobRunId')) {
                clearTimeout(parseInt(localStorage.getItem('jobRunId') || ''));
                localStorage.setItem('jobRunId', '');
            }
        };
    }, []);

    const handleVideoControls = () => {
        const timeoutId = setTimeout(() => {
            setHideVideoControls(true);
        }, 1000);

        return () => clearTimeout(timeoutId);
    };
    const debounceFn = _debounce(handleVideoControls, 1000);

    const downloadVideo = (url: any) => {
        if (isInApp) {
            setOpenSaveVideoInAppModal(true);
            return;
        }
        triggerDataLayer('video_save', {
            scene_number: `scene ${sceneNumber + 1}`,
            scene_description: videoTitle,
            path: festiveModeOn
                ? DataLayerEventPath.FESTIVE
                : DataLayerEventPath.STANDARD,
        });
        const link = document.createElement('a');
        link.href = url + '?download=1';
        link.setAttribute('download', `qatar-video.mp4`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    };

    const onClickQVerse = () => {
        triggerDataLayer('qverse_click', {
            scene_number: `scene ${sceneNumber + 1}`,
            scene_description: videoTitle,
            path: festiveModeOn
                ? DataLayerEventPath.FESTIVE
                : DataLayerEventPath.STANDARD,
        });
    };

    const onClickQatarAirways = () => {
        triggerDataLayer('brand_website_click', {
            scene_number: `scene ${sceneNumber + 1}`,
            scene_description: videoTitle,
            path: festiveModeOn
                ? DataLayerEventPath.FESTIVE
                : DataLayerEventPath.STANDARD,
        });
    };

    return (
        <>
            {isPageReady ? (
                <>
                    <div
                        className={clsx(styles.wrap, 'wrap', {
                            [styles.progressContainer]: pageState === 'initial',
                            [styles.readyContainer]: pageState === 'isReady',
                            [styles.frenchReadyContainer]:
                                (isFrenchLanguage || isSpanishLanguage) &&
                                pageState === 'isReady',
                            [styles.italianReadyContainer]:
                                isItalianLanguage && pageState === 'isReady',
                        })}
                    >
                        <p
                            className={clsx('h1', styles.mobileTitle, {
                                [styles.mobileTitleVisible]:
                                    pageState === 'isReady',
                            })}
                        >
                            {t('your_video_is_ready')}
                        </p>
                        <p
                            className={clsx(
                                styles.mobileTitle,
                                {
                                    [styles.mobileTitleVisible]:
                                        pageState === 'isReady',
                                    [styles.mobileTitleItalian]:
                                        isItalianLanguage &&
                                        pageState === 'isReady',
                                },
                                styles.mobileSubTitle
                            )}
                        >
                            {t('video_ready_desc')}
                        </p>
                        <div
                            className={clsx(styles.videobox, {
                                [styles.readyVideo]: pageState === 'isReady',
                                [styles.isMaximized]: isMaximized,
                                [styles.hideVideoControls]:
                                    isMaximized && hideVideoControls,
                            })}
                            onMouseMove={() => {
                                if (isMaximized) {
                                    setHideVideoControls(false);
                                    debounceFn();
                                }
                            }}
                        >
                            {videoUrl && pageState === 'isReady' ? (
                                <video
                                    ref={videoRef}
                                    autoPlay
                                    loop
                                    preload="metadata"
                                    playsInline
                                    className={styles.video}
                                >
                                    <source
                                        src={`${videoUrl}#t=${
                                            posterTimeSecs || 0
                                        }`}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            ) : null}
                            {pageState === 'isReady' ? (
                                <button
                                    type="button"
                                    id="control-video-play-button"
                                    className={clsx(styles.playBtn, {
                                        [styles.hide]: isVideoPlays,
                                    })}
                                    onClick={() => {
                                        if (
                                            playsCount === 0 &&
                                            videoRef?.current
                                        ) {
                                            videoRef.current.currentTime = 0;
                                            setPlaysCount(1);
                                        }
                                        setIsVideoPlays((prev) => !prev);
                                    }}
                                    title={
                                        isVideoPlays ? t('pause') : t('play')
                                    }
                                >
                                    {playsCount > 0 ||
                                    !(isAndroid && isFirefox) ? (
                                        <img
                                            src={
                                                isVideoPlays
                                                    ? pauseIcon
                                                    : playIcon
                                            }
                                            alt=""
                                        />
                                    ) : null}
                                </button>
                            ) : null}
                            {pageState === 'isReady' ? (
                                <VideoVolume
                                    videoRef={videoRef}
                                    className={styles.videoVolume}
                                />
                            ) : null}

                            {pageState === 'isReady' && (
                                <button
                                    id="maximise-video-button"
                                    type="button"
                                    className={styles.maximizeBtn}
                                    title={
                                        isMaximized
                                            ? t('minimize')
                                            : t('maximize')
                                    }
                                    onClick={() =>
                                        setIsMaximized((prev) => !prev)
                                    }
                                >
                                    <img src={maximizeIcon} alt="" />
                                </button>
                            )}
                            {pageState === 'initial' ? (
                                <iframe
                                    src={getYoutubeLink()}
                                    width={Math.min(
                                        Math.max(windowWidth - 30, 0),
                                        720
                                    )}
                                    title="YouTube Ad"
                                    className={styles.embedVideo}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            ) : null}
                        </div>
                        <div
                            className={clsx(styles.descbox, {
                                [styles.readyDescbox]: pageState === 'isReady',
                                [styles.frenchReadyDescbox]:
                                    (isFrenchLanguage || isSpanishLanguage) &&
                                    pageState === 'isReady',
                                [styles.germanReadyDescbox]:
                                    (isGermanLanguage ||
                                        isPortugueseLanguage) &&
                                    pageState === 'isReady',
                                [styles.italianReadyDescbox]:
                                    isItalianLanguage &&
                                    pageState === 'isReady',
                            })}
                        >
                            {pageState === 'isReady' ? (
                                <>
                                    <p className="h1">
                                        {t('your_video_is_ready')}
                                    </p>
                                    <p
                                        className={clsx(styles.body1, 'body1', {
                                            [styles.body1FrenchLanguage]:
                                                isFrenchLanguage ||
                                                isItalianLanguage ||
                                                isSpanishLanguage,
                                        })}
                                    >
                                        {t('video_ready_desc')}
                                    </p>
                                    <div className={styles.btnGroup}>
                                        {isBrowser ? (
                                            <Button
                                                className={styles.button}
                                                type="button"
                                                id="share-video-button"
                                                color="white"
                                                onClick={handleSharingDesktop}
                                                title={t('share_video')}
                                            >
                                                {t('share_video')}
                                            </Button>
                                        ) : (
                                            <Button
                                                className={styles.button}
                                                type="button"
                                                id="share-video-button"
                                                color="white"
                                                onClick={handleSharing}
                                                title={t('share_video')}
                                            >
                                                {t('share_video')}
                                            </Button>
                                        )}
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            className={styles.button}
                                            title={t('save_video')}
                                            id="download-video-button"
                                            onClick={() =>
                                                downloadVideo(videoUrl)
                                            }
                                        >
                                            {t('save_video')}
                                        </Button>

                                        <ResultShare
                                            shareUrl={videoShortUrl || ''}
                                            title={videoTitle as string}
                                            open={shareDialogOpen}
                                            handleClose={() =>
                                                setShareDialogOpen(false)
                                            }
                                            sceneNumber={sceneNumber}
                                            festiveModeOn={festiveModeOn}
                                        />
                                        <a
                                            href={
                                                festiveModeOn
                                                    ? 'https://www.qatarairways.com/Valentines'
                                                    : 'https://www.qatarairways.com/html/redirect/QR/qverse/B777/qsuite?utm_source=ai_microsite&utm_medium=referral&utm_campaign=brand_campaign&utm_content=qsuite&cid=BDALL781970'
                                            }
                                            className={styles.createNew}
                                            title={
                                                festiveModeOn
                                                    ? t(
                                                          'experience_q_verse_festive'
                                                      )
                                                    : t('experience_q_verse')
                                            }
                                            id="create-new-video-button"
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={onClickQVerse}
                                        >
                                            {festiveModeOn
                                                ? t(
                                                      'experience_q_verse_festive'
                                                  )
                                                : t('experience_q_verse')}
                                        </a>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p
                                        className="body1"
                                        style={{ margin: '0 0 0 0' }}
                                    >
                                        {t('video_processing')}
                                    </p>
                                    <div
                                        className={styles.progressBarContainer}
                                    >
                                        <LinearProgress
                                            className={styles.progressLine}
                                        />
                                    </div>
                                    <p
                                        className="h1"
                                        style={{ marginTop: '18px' }}
                                    >
                                        {t('video_will_be_ready_soon')}
                                    </p>
                                    <p
                                        className="body1"
                                        style={{ margin: '24px 0 30px 0' }}
                                    >
                                        {t('you_will_be_redirected')}
                                    </p>
                                    <p
                                        className="body1"
                                        style={{ margin: '24px 0 30px 0' }}
                                    >
                                        {t('you_will_be_redirected_2')}
                                    </p>
                                    <Box mb={3}>
                                        <Button
                                            type={'button'}
                                            color="white"
                                            className={styles.notifyModalButton}
                                            onClick={() =>
                                                setOpenNotifyModal(true)
                                            }
                                        >
                                            {t('notify_me_via_email')}
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </div>
                    </div>
                    {pageState === 'isReady' ? (
                        <div
                            className={clsx(
                                'wrap',
                                styles.wrap,
                                styles.qatarLinkWrap
                            )}
                        >
                            <a
                                href={'https://www.qatarairways.com'}
                                target="_blank"
                                rel="noreferrer"
                                className={styles.qatarLink}
                                title={t('qatar_airways_com')}
                                onClick={onClickQatarAirways}
                            >
                                {t('qatar_airways_com')}
                            </a>
                        </div>
                    ) : null}
                </>
            ) : null}
            <GoogleReCaptchaProvider
                reCaptchaKey={
                    process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY as string
                }
            >
                <NotifyModal
                    open={openNotifyModal}
                    handleClose={() => setOpenNotifyModal(false)}
                    jobId={jobId}
                    festiveModeOn={festiveModeOn}
                />
            </GoogleReCaptchaProvider>
            <ErrorModal
                open={openErrorModal}
                handleClose={() => setOpenErrorModal(false)}
                jobId={jobId}
            />
            <SaveVideoInAppModal
                open={openSaveVideoInAppModal}
                handleClose={() => setOpenSaveVideoInAppModal(false)}
            />
        </>
    );
};
export default Result;
