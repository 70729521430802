import React, { useEffect, useMemo, useRef } from 'react';
import Link from '../../components/ui/Link';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { FaceSwapAPI } from '../../bdreams/FaceSwapAPI';
import triggerDataLayer, {
    DataLayerEventPath,
} from '../../helpers/dataLayerTrigger';
import pauseIcon from '../../assets/icons/pause.svg';
import playIcon from '../../assets/icons/play.svg';
import VideoVolume from '../../components/ui/VideoVolume';
import { isAndroid, isFirefox, isMobile } from 'react-device-detect';
import FestiveToggleButton from '../../components/ui/FestiveToggle';

const SelectStory = ({
    canUseFestiveMode,
    festiveModeOn,
    setFestiveModeOn,
}: {
    canUseFestiveMode: boolean;
    festiveModeOn: boolean;
    setFestiveModeOn: (value: boolean) => void;
}) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const isChineseLanguage = language === 'zh-CN';

    const [isVideoPlays, setIsVideoPlays] = React.useState(true);
    const videoRef = useRef<any>();
    const [mouseOverVideo, setMouseOverVideo] = React.useState(false);
    const [videoDidPlay, setVideoDidPlay] = React.useState(false);

    useEffect(() => {
        document.addEventListener('click', onWindowClick);
        return () => {
            document.removeEventListener('click', onWindowClick);
        };
    }, [isVideoPlays, videoDidPlay]);

    useEffect(() => {
        if (isVideoPlays) {
            videoRef.current?.play();
        } else {
            videoRef.current?.pause();
        }
    }, [isVideoPlays, mouseOverVideo]);

    const tryToPlayTheVideo = () => {
        let promise = videoRef.current?.play();
        if (promise !== undefined) {
            promise
                .then(() => {
                    setIsVideoPlays(true);
                    setVideoDidPlay(true);
                })
                .catch((err: any) => {
                    setIsVideoPlays(false);
                    setVideoDidPlay(false);
                });
        }
    };

    useEffect(() => {
        tryToPlayTheVideo();
    }, []);

    const onWindowClick = () => {
        if (!videoDidPlay && !isVideoPlays) {
            tryToPlayTheVideo();
        }
    };

    const onClickStart = () => {
        triggerDataLayer('start', {
            path: (canUseFestiveMode && festiveModeOn)
                ? DataLayerEventPath.FESTIVE
                : DataLayerEventPath.STANDARD,
        });
    };

    useEffect(() => {
        document.title = t('home_page_title');
    }, [t]);

    const videoUrl =
        process.env.NODE_ENV === 'production'
            ? `/content/intro/${(
                  localStorage.getItem('user-selected-language') || 'en'
              ).toLowerCase()}.mp4`
            : FaceSwapAPI.videos[0]?.url;

    return (
        <div className={clsx(styles.wrap, 'wrap')}>
            <div
                onMouseEnter={() => {
                    if (!isMobile) {
                        setMouseOverVideo(true);
                    }
                }}
                onMouseLeave={() => {
                    if (isVideoPlays) {
                        if (!isMobile) {
                            setMouseOverVideo(false);
                        }
                    }
                }}
                className={styles.videobox}
            >
                <video
                    ref={videoRef}
                    src={videoUrl}
                    autoPlay
                    loop
                    playsInline
                    className={styles.video}
                    title={t('title_video_preview')}
                    onLoadedMetadata={() => (videoRef.current.controls = false)}
                    onPlay={() => !isVideoPlays && setIsVideoPlays(true)}
                />
                <VideoVolume
                    videoRef={videoRef}
                    className={styles.videoVolume}
                />
                <button
                    type="button"
                    id={`play-homepage-video-button`}
                    className={clsx(styles.playBtn, {
                        [styles.hide]: isVideoPlays,
                    })}
                    onClick={() => {
                        setIsVideoPlays((prev) => !prev);
                    }}
                    title={isVideoPlays ? t('pause') : t('play')}
                >
                    {videoDidPlay || !(isAndroid && isFirefox) ? (
                        <img src={isVideoPlays ? pauseIcon : playIcon} alt="" />
                    ) : null}
                </button>
                <Link
                    id="start-story-button"
                    to="/selectyourscene"
                    title={t('start')}
                    onClick={onClickStart}
                >
                    {t('start')}
                </Link>
            </div>
            <div
                className={clsx(styles.descbox, {
                    [styles.descboxChinese]: isChineseLanguage,
                    [styles.canUseFestiveMode]: canUseFestiveMode,
                })}
                aria-label={(canUseFestiveMode) ? t('begin_love_story_festive') : t('begin_love_story')}
            >
                {canUseFestiveMode ? (
                    <div className={styles.toggleContainer}>
                        <FestiveToggleButton
                            festiveModeOn={festiveModeOn}
                            setFestiveModeOn={setFestiveModeOn}
                            canUseFestiveMode={canUseFestiveMode}
                        />
                    </div>
                ) : null}
                <h1 className="h1">{(canUseFestiveMode) ? t('begin_love_story_festive') : t('begin_love_story')}</h1>
                <p className="body1">{(canUseFestiveMode) ? t('swap_your_face_festive') : t('swap_your_face')}</p>
                <Link
                    id="desktop-start-story-button"
                    to="/selectyourscene"
                    title={t('start')}
                    onClick={onClickStart}
                >
                    {t('start')}
                </Link>
            </div>
        </div>
    );
};
export default SelectStory;
