import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FaceSwapAPI } from '../../bdreams/FaceSwapAPI';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Thumbs } from 'swiper/modules';
import { Swiper as SwiperType } from 'swiper/types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from '../../components/ui/Link';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import playIcon from '../../assets/icons/play.svg';
import pauseIcon from '../../assets/icons/pause.svg';
import closeIcon from '../../assets/icons/close.svg';
import nextIcon from '../../assets/icons/next.svg';
import prevIcon from '../../assets/icons/prev.svg';
import { getSceneThumbnailTimestamp } from '../../helpers/utils';
import triggerDataLayer, {
    DataLayerEventPath,
} from '../../helpers/dataLayerTrigger';
import { useNavigate } from 'react-router-dom';
import FestiveToggleButton from '../../components/ui/FestiveToggle';
import candyIcon from '../../assets/icons/candy-on.svg';

const ScreenChooseVideo = ({
    festiveModeOn,
    setFestiveModeOn,
    canUseFestiveMode,
}: ScreenChooseVideoProps) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const isGermanLanguage = language === 'de';

    const [swiper, setSwiper] = useState<SwiperType | null>(null);
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
    const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
    const [isContentHidden, setIsContentHidden] = useState<boolean>(false);
    const [isVideoPlays, setIsVideoPlays] = useState<boolean>(true);
    const isTablet = useMediaQuery('(max-width:768px)');
    const navigate = useNavigate();
    const [preloadedImages, setPreloadedImages] = useState<number[]>([]);

    const videoRefs = useRef<Array<HTMLVideoElement | null>>([]);

    useEffect(() => {
        document.title = t('select_page_title');
    }, [t]);

    useEffect(() => {
        thumbsSwiper?.slideTo(activeSlideIndex);
    }, [activeSlideIndex, thumbsSwiper]);

    useEffect(() => {
        if (!isTablet) {
            let promise = videoRefs.current[swiper?.realIndex]?.play();
            if (promise !== undefined) {
                promise
                    .then(() => {
                        setIsVideoPlays(true);
                    })
                    .catch(() => {
                        setIsVideoPlays(false);
                    });
            }
        }
    }, [isTablet, videoRefs.current[swiper?.realIndex]]);

    useEffect(() => {
        if (!isTablet) {
            if (isVideoPlays) {
                videoRefs.current[swiper?.realIndex]?.play();
            } else {
                videoRefs.current[swiper?.realIndex]?.pause();
            }
        }
    }, [isTablet, isVideoPlays]);

    const onClickSceneNumber = (key: number) => () => {
        triggerDataLayer('scene_selector_click', {
            scene_number: `scene ${key + 1}`,
            scene_description: FaceSwapAPI?.videos?.[key]?.title,
        });
    };

    const onClickSelectScene = (key: number) => () => {
        navigate(`/scene${key + 1}/create`, {
            state: { videoId: FaceSwapAPI.videos[key].id },
        });

        triggerDataLayer('scene_selected', {
            scene_number: `scene ${key + 1}`,
            scene_description: FaceSwapAPI?.videos?.[key]?.title,
            path: festiveModeOn
                ? DataLayerEventPath.FESTIVE
                : DataLayerEventPath.STANDARD,
        });
    };

    const handleContentHideOnMobile = () => {
        setIsContentHidden((prev) => !prev);

        if (isContentHidden) {
            document.body.classList.remove('fixed');
        } else {
            document.body.classList.add('fixed');
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const getPosition = () => {
        const swiper = document.getElementById('swiper');
        const linkButton = document.getElementById(
            `select-video-${FaceSwapAPI.videos[activeSlideIndex].title}`
        );

        if (!linkButton) {
            return;
        }

        const swiperLeft = swiper?.offsetLeft || 0;

        return {
            top: linkButton.offsetTop,
            left: swiperLeft + linkButton.offsetLeft,
            height: linkButton.offsetHeight,
            width: linkButton.clientWidth,
        };
    };

    const doPreloadImages = (key: number) => {
        const videoState = FaceSwapAPI.videos[key];
        if (videoState) {
            const images = videoState.targets.slice();
            for (let idx = 0; idx < images.length; idx++) {
                if (!preloadedImages[key * 10 + idx]) {
                    preloadedImages[key * 10 + idx] = 1;
                    new Image().src = images[idx].url;
                    new Image().src = images[idx].urlThumb;
                    new Image().src = images[idx].urlThumbWide;
                }
            }
        }
    };

    return (
        <div className={clsx(styles.wrap, 'wrap')}>
            <div
                // @ts-ignore
                style={{
                    ...getPosition(),
                    position: 'absolute',
                    zIndex: 1000,
                    cursor: 'pointer',
                }}
                onClick={onClickSelectScene(activeSlideIndex)}
            />
            <Swiper
                id="swiper"
                aria-label={t('aria_label_scenes_selector')}
                dir={document.body.dir}
                spaceBetween={16}
                slidesPerView={1}
                allowTouchMove={false}
                fadeEffect={{ crossFade: false }}
                onSwiper={(swiper: SwiperType) => setSwiper(swiper)}
                onSlideChange={(swiper: SwiperType) => {
                    videoRefs.current[swiper.previousIndex]?.pause();
                    if (!isTablet && true) {
                        videoRefs.current[swiper.realIndex]?.play();
                    }
                    setActiveSlideIndex(swiper.realIndex);
                    doPreloadImages(swiper.realIndex);
                }}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[EffectFade, Thumbs]}
                effect="fade"
                className={clsx(
                    {
                        [styles.above]: isContentHidden,
                    },
                    styles.slider
                )}
            >
                {FaceSwapAPI.videos.map((item, key) => (
                    <SwiperSlide className={styles.slide} key={key}>
                        <div
                            className={clsx(
                                { [styles.above]: isContentHidden },
                                styles.videobox,
                                {
                                    [styles.festiveModeOn]:
                                        festiveModeOn &&
                                        isTablet &&
                                        canUseFestiveMode,
                                }
                            )}
                        >
                            <button
                                type="button"
                                id={`hide-${item.title}-content-button`}
                                className={clsx(
                                    { [styles.visible]: isContentHidden },
                                    styles.closeBtn
                                )}
                                onClick={() => {
                                    handleContentHideOnMobile();
                                    videoRefs.current[
                                        swiper.realIndex
                                    ]?.pause();
                                    videoRefs.current[
                                        swiper.realIndex
                                    ]!.currentTime =
                                        getSceneThumbnailTimestamp(key);
                                }}
                            >
                                <img src={closeIcon} alt="" />
                            </button>
                            <>
                                <video
                                    key={`video-preview-${key}-festive-${item.url}`}
                                    ref={(el) => (videoRefs.current[key] = el)}
                                    {...(isVideoPlays && {
                                        autoPlay:
                                            !isTablet &&
                                            activeSlideIndex === key &&
                                            true,
                                    })}
                                    playsInline
                                    loop
                                    // muted
                                    className={styles.video}
                                >
                                    <source
                                        src={`${item.url}#t=${
                                            isTablet
                                                ? getSceneThumbnailTimestamp(
                                                      key
                                                  ) +
                                                  (festiveModeOn &&
                                                  [2, 4, 9].includes(key)
                                                      ? 3
                                                      : 0)
                                                : 0
                                        }`}
                                        type="video/mp4"
                                    />
                                </video>
                                <button
                                    type="button"
                                    id={`play-${item.title}-video-button`}
                                    className={styles.playBtn}
                                    onClick={() => {
                                        setIsVideoPlays((prev) => !prev);
                                    }}
                                    title={
                                        isVideoPlays ? t('pause') : t('play')
                                    }
                                >
                                    <img
                                        src={
                                            isVideoPlays ? pauseIcon : playIcon
                                        }
                                        alt=""
                                    />
                                </button>
                            </>
                        </div>
                        <div
                            className={clsx(styles.descbox, {
                                [styles.descboxHidden]: isContentHidden,
                                [styles.festiveModeOn]:
                                    festiveModeOn &&
                                    isTablet &&
                                    canUseFestiveMode,
                                [styles.canUseFestiveMode]: canUseFestiveMode,
                            })}
                        >
                            <p>{item.description}</p>
                            <h1>{item.title}</h1>
                            <button
                                type="button"
                                id={`hide-${item.title}-button`}
                                onClick={() => {
                                    handleContentHideOnMobile();
                                    if (isTablet) {
                                        videoRefs.current[
                                            swiper.realIndex
                                        ]!.currentTime = 0;
                                    }
                                    videoRefs.current[
                                        swiper?.realIndex
                                    ]?.play();
                                }}
                            >
                                <img src={playIcon} alt="" />
                            </button>

                            <Link
                                to={`/scene${key + 1}/create` as string}
                                id={`select-video-${item.title}`}
                                state={{ state: { videoId: item.id } }}
                                title={`${t('select_scene', {
                                    number: key + 1,
                                })}`}
                                color={
                                    [2, 4, 9].includes(key) &&
                                    festiveModeOn &&
                                    canUseFestiveMode
                                        ? 'pink'
                                        : 'burgundy'
                                }
                                {...(activeSlideIndex !== key
                                    ? { tabIndex: -1 }
                                    : {})}
                                onClick={onClickSelectScene(key)}
                            >
                                {[2, 4, 9].includes(key) &&
                                    festiveModeOn &&
                                    canUseFestiveMode && (
                                        <img
                                            src={candyIcon}
                                            alt={t('festive_on')}
                                        />
                                    )}
                                {t('select_scene', { number: key + 1 })}
                            </Link>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {canUseFestiveMode ? (
                <div className={styles.toggleContainer}>
                    <FestiveToggleButton
                        festiveModeOn={festiveModeOn}
                        setFestiveModeOn={setFestiveModeOn}
                        canUseFestiveMode={canUseFestiveMode}
                    />
                </div>
            ) : null}
            <div
                className={clsx(styles.thumbSliderBox, {
                    [styles.thumbSliderBoxGerman]: isGermanLanguage,
                    [styles.canUseFestiveMode]: canUseFestiveMode,
                })}
            >
                <Swiper
                    dir={document.body.dir}
                    onSwiper={(swiper: SwiperType) => {
                        setThumbsSwiper(swiper);
                        setTimeout(() => {
                            if (swiper) {
                                swiper.slideTo(0);
                            }
                        }, 50);
                    }}
                    onInit={(swiper: SwiperType) => {
                        swiper.slideTo(3);
                        doPreloadImages(0);
                    }}
                    freeMode={true}
                    watchSlidesProgress={true}
                    navigation
                    modules={[Navigation, Thumbs]}
                    fadeEffect={{ crossFade: false }}
                    breakpoints={{
                        250: {
                            slidesPerView: 'auto',
                            spaceBetween: 16,
                            simulateTouch: true,
                            allowTouchMove: true,
                        },
                        769: {
                            slidesPerView: 3,
                            spaceBetween: 24,
                            simulateTouch: true,
                            allowTouchMove: true,
                            centeredSlides: true,
                            centeredSlidesBounds: true,
                        },
                        1025: {
                            slidesPerView: 2,
                            spaceBetween: 24,
                            simulateTouch: true,
                            allowTouchMove: true,
                            centeredSlides: true,
                            centeredSlidesBounds: true,
                        },
                        1316: {
                            slidesPerView: 4,
                            spaceBetween: 24,
                            simulateTouch: true,
                            allowTouchMove: true,
                            centeredSlides: true,
                            centeredSlidesBounds: true,
                        },
                    }}
                    className={clsx(
                        styles.thumbSlider,
                        {
                            [styles.fixed]: FaceSwapAPI.videos.length < 4,
                            [styles.canUseFestiveMode]: canUseFestiveMode,
                        },
                        styles.parentSlider
                    )}
                >
                    {FaceSwapAPI.videos.map((item, key) => (
                        <SwiperSlide
                            key={key}
                            className={clsx(styles.thumbSliderSlide, {
                                [styles.thumbSliderSlideFestive]:
                                    festiveModeOn && [2, 4, 9].includes(key),
                            })}
                            tabIndex={0}
                            onClick={() => {
                                swiper?.slideTo(key);
                                onClickSceneNumber(key)();
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && swiper) {
                                    swiper.slideTo(key);
                                    onClickSceneNumber(key)();
                                }
                            }}
                            id={`thumb-slide-${key}`}
                        >
                            {t('scene', { number: key + 1 })}
                        </SwiperSlide>
                    ))}
                </Swiper>

                <button
                    type="button"
                    className={clsx(styles.slideArrow, styles.prevScene, {
                        [styles.hidden]: activeSlideIndex === 0,
                    })}
                    onClick={() => swiper?.slidePrev()}
                    title={t('previous')}
                >
                    <img src={prevIcon} alt="Previous" />
                </button>

                <button
                    type="button"
                    className={clsx(styles.slideArrow, styles.nextScene, {
                        [styles.hidden]:
                            activeSlideIndex === FaceSwapAPI.videos.length - 1,
                    })}
                    onClick={() => swiper?.slideNext()}
                    title={t('next')}
                >
                    <img src={nextIcon} alt="Next" />
                </button>
            </div>
        </div>
    );
};

export type ScreenChooseVideoProps = {
    festiveModeOn: boolean;
    setFestiveModeOn: (value: boolean) => void;
    canUseFestiveMode: boolean;
};

export default ScreenChooseVideo;
